import { render, staticRenderFns } from "./AddTaltent.vue?vue&type=template&id=8bc43c20&scoped=true&"
import script from "./AddTaltent.vue?vue&type=script&lang=js&"
export * from "./AddTaltent.vue?vue&type=script&lang=js&"
import style0 from "./AddTaltent.vue?vue&type=style&index=0&id=8bc43c20&prod&lang=css&"
import style1 from "./AddTaltent.vue?vue&type=style&index=1&id=8bc43c20&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bc43c20",
  null
  
)

export default component.exports